// import React, { Fragment, useState } from 'react';
import { Box, Typography, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
// import { PageLoader, PageError } from 'components';
import { readableJapaneseDateFormat } from 'shared/utils/dateTime';
import { valueToString } from 'shared/utils/misc';
// import api from 'shared/utils/api';
// import localData from 'shared/utils/localData';
// import useApi from 'shared/hooks/api';
// import { SHARK_API } from 'shared/constants/apis';

// import MDBox from 'components/MDBox';

const propTypes = {
  thisCase: PropTypes.any,
};

const DetailPanelContent = ({ thisCase }) => {
  return (
    <Box
      sx={{
        margin: 2,
        padding: 2,
        // border: '1px solid #ccc',
        // borderRadius: '4px',
        // display: 'grid',
      }}>
      <Grid container spacing={2}>
        {/* first line */}
        <Grid item xs={3}>
          <Typography variant="body3" gutterBottom>
            {'id'}: {valueToString(thisCase.id)}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body3" gutterBottom>
            {'caseIdentifierId'}: {valueToString(thisCase.caseIdentifierId)}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body3" gutterBottom>
            {'diagnosticNote'}: {valueToString(thisCase.diagnosticNote)}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body3" gutterBottom>
            {'priority'}: {valueToString(thisCase.priority)}
          </Typography>
        </Grid>

        {/* 2nd line */}
        <Grid item xs={3}>
          <Typography variant="body3" gutterBottom>
            {`doctorId: ${thisCase.doctorId}`}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body3" gutterBottom>
            {`doctor用户名: ${thisCase.doctorUsername}`}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body3" gutterBottom>
            {'progress'}: {valueToString(thisCase.progress)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body3" gutterBottom>
            {'hasCreatedWage'}: {valueToString(thisCase.hasCreatedWage)}
          </Typography>
        </Grid>

        {/* 3rd line */}

        {/* 4th line */}
        <Grid item xs={4}>
          <Stack direction="row" spacing={2}>
            <Typography variant="body3" gutterBottom>
              {'dicomTags:'}
            </Typography>
            <ReactJson src={thisCase.dicomTags} collapsed={false} />
          </Stack>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'createdAt'}: {readableJapaneseDateFormat(thisCase.createdAt)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'updatedAt'}: {readableJapaneseDateFormat(thisCase.updatedAt)}
          </Typography>
        </Grid>

        {/* 5th line */}
        <Grid item xs={12}>
          <Typography variant="body3" gutterBottom>
            {'webUrl'}: {valueToString(thisCase.webUrl)}
          </Typography>
        </Grid>

        {/* // end of grid items */}
      </Grid>
    </Box>
  );
};

DetailPanelContent.propTypes = propTypes;

export default DetailPanelContent;
