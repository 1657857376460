// import React, { Fragment, useState } from 'react';
import { Box, Typography, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
// import ReactJson from 'react-json-view';
import { PageLoader, PageError } from 'components';
import { readableJapaneseDateFormat } from 'shared/utils/dateTime';
import { valueToString } from 'shared/utils/misc';
// import api from 'shared/utils/api';
// import localData from 'shared/utils/localData';
import useApi from 'shared/hooks/api';
import { SHARK_API } from 'shared/constants/apis';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import MDBox from 'components/MDBox';
import DetailPanelContentAttachments from './components/attachments';
import CaseTable from './components/caseTable';

const propTypes = {
  issue: PropTypes.any,
};

const DetailPanelContent = ({ issue }) => {
  const [{ data, error }, fetchIssue] = useApi.get(
    `${SHARK_API.ISSUES_KENSHIN}/${issue.id}`,
    {}
  );

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { attachments, cases } = data;

  const diagnosisStats = cases.reduce(
    (acc, { diagnosis }) => ((acc[diagnosis] = (acc[diagnosis] || 0) + 1), acc),
    {}
  );

  return (
    <Box
      sx={{
        margin: 2,
        padding: 2,
        // border: '1px solid #ccc',
        // borderRadius: '4px',
        // display: 'grid',
      }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'id'}: {valueToString(issue.id)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'srcOrgId'}: {valueToString(issue.srcOrgId)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'dstOrgId'}: {valueToString(issue.dstOrgId)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'department'}: {valueToString(issue.department)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'priority'}: {valueToString(issue.priority)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'updatedAt'}: {readableJapaneseDateFormat(issue.updatedAt)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'description'}: {valueToString(issue.description)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'descriptionText'}: {valueToString(issue.descriptionText)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'hasCreatedBill'}: {valueToString(issue.hasCreatedBill)}
          </Typography>
        </Grid>
        {/* // end of grid items */}
      </Grid>
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5 }}>
        {'Attachments: '}
      </Typography>
      <DetailPanelContentAttachments
        attachments={attachments}
        keyNameHeader="attachments"
      />
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5 }}>
        {'Cases: '}
      </Typography>
      <Typography variant="body3" gutterBottom>
        {`status: ${valueToString(diagnosisStats)};   total number: ${
          cases.length
        }`}
      </Typography>

      <MDBox>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CaseTable cases={cases} />
        </LocalizationProvider>
      </MDBox>
    </Box>
  );
};

DetailPanelContent.propTypes = propTypes;

export default DetailPanelContent;
