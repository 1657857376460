import React from 'react';
import PropTypes from 'prop-types';
// import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const propType = {
  selectedDate: PropTypes.instanceOf(Date),
  setSelectedDate: PropTypes.func.isRequired,
  labelText: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export const CustomDatePicker = ({
  selectedDate,
  setSelectedDate,
  labelText,
  minDate,
  maxDate,
}) => {
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={labelText}
        value={selectedDate}
        onChange={handleDateChange}
        minDate={minDate}
        maxDate={maxDate}
        // renderInput={(params) => <TextField {...params} />}
        slotProps={{ textField: { variant: 'outlined' } }}
      />
    </LocalizationProvider>
  );
};

CustomDatePicker.propTypes = propType;
