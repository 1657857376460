import { useState } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';

// react-router-dom components
import { useLocation, NavLink } from 'react-router-dom';

// @mui material components
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// components for Sidenav
import SidenavCollapse from './SidenavCollapse';
import SidenavList from './SidenavList';
import SidenavItem from './SidenavItem';

// Custom styles for the Sidenav
import SidenavRoot from './SidenavRoot';
import sidenavLogoLabel from './styles/sidenav';

// Images
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
// import WorkIcon from '@mui/icons-material/Work';
// import ArticleIcon from '@mui/icons-material/Article';
import AssignmentIcon from '@mui/icons-material/Assignment';

import localData from 'shared/utils/localData';

import { useMaterialUIController, setMiniSidenav } from 'context';
import { USER_DATA } from 'shared/constants/users';

const propTypes = {
  color: PropTypes.string,
  brand: PropTypes.string,
  brandName: PropTypes.string,
};

const DashboardSidenav = ({ color, brand, brandName }) => {
  const [openCollapse, setOpenCollapse] = useState(false);

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const { pathname } = location;
  const username = localData.get(USER_DATA.USERNAME);

  let textColor = 'white';
  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = 'dark';
  } else if (whiteSidenav && darkMode) {
    textColor = 'inherit';
  }

  const signOut = async () => {
    if (
      process.env.REACT_APP_AUTH_SERVER === 'aws-cognito' &&
      process.env.REACT_APP_SIGN_IN_PAGE === 'aws-custom'
    ) {
      try {
        await Auth.signOut();
        window.location.reload();
      } catch (error) {
        alert('error sign out: ', error);
      }
    }
  };

  return (
    <SidenavRoot
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}>
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          <MDBox component="img" src={brand} alt="Brand" width="3rem" />
          <MDBox
            width={'70%'}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
            <MDTypography
              component="h1"
              variant="button"
              fontWeight="bold"
              color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider light={true} />

      <SidenavCollapse
        key="profile"
        name="profile"
        icon={<PersonIcon fontSize="large" />}
        active={openCollapse}
        open={openCollapse}
        onClick={() => {
          setOpenCollapse((prevState) => !prevState);
        }}>
        <SidenavList key={'logout'}>
          <SidenavItem color={color} name="LOGOUT" onClick={signOut} />
        </SidenavList>
      </SidenavCollapse>

      <Divider light={true} />

      <NavLink to="/users" key="users">
        <SidenavCollapse
          name="Users"
          noCollapse={true}
          icon={<PeopleIcon fontSize="medium" />}
          active={pathname === '/users'}
        />
      </NavLink>

      <NavLink to="/organizations" key="organizations">
        <SidenavCollapse
          name="Organizations"
          noCollapse={true}
          icon={<CorporateFareIcon fontSize="medium" />}
          active={pathname === '/organizations'}
        />
      </NavLink>
      <NavLink to="/issues" key="issues">
        <SidenavCollapse
          name="Issues"
          noCollapse={true}
          icon={<AssignmentIcon fontSize="medium" />}
          active={pathname === '/issues'}
        />
      </NavLink>
      <NavLink to="/issues-kenshin" key="issues-kenshin">
        <SidenavCollapse
          name="Issues - Kenshin"
          noCollapse={true}
          icon={<AssignmentIcon fontSize="medium" />}
          active={pathname === '/issues-kenshin'}
        />
      </NavLink>
      <NavLink to="/logs" key="logs">
        <SidenavCollapse
          name="logs"
          noCollapse={true}
          icon={<ReceiptLongIcon fontSize="medium" />}
          active={pathname === '/logs'}
        />
      </NavLink>
    </SidenavRoot>
  );
};

DashboardSidenav.propTypes = propTypes;

export default DashboardSidenav;
