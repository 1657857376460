import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import Select from 'react-select';
// import { Route } from 'react-router-dom';

//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import intl from 'react-intl-universal';
import { ThemeProvider } from '@mui/material/styles';
import { Grid } from '@material-ui/core';
import Card from '@mui/material/Card';
// import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
// functions
import api from 'shared/utils/api';
import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import { getFirstDayPreviousMonth } from 'shared/utils/dateTime';

// constants
import { SHARK_API } from 'shared/constants/apis';
// import { USER_DATA } from 'shared/constants/users';
// import { MatchPropTypes } from 'shared/propTypes/matchType';
// import { HistoryProptypes } from 'shared/propTypes/historyType';

// components and parts
import { PageLoader, PageError, Modal, RefreshButton } from 'components';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';
import { CustomDatePicker } from 'pages/parts/DatePicker';
import Table from './table';
// import { USER_COMMENT } from 'shared/constants/comment';

const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '40px', // Custom height
    minWidth: '400px',
    maxWidth: '800px',
  }),

  multiValue: (provided, state) => ({
    ...provided,
    height: '30px', // Set the height of the chip
    display: 'flex',
    alignItems: 'center', // This ensures vertical centering of the content
    justifyContent: 'center', // This centers the content horizontally (if needed)
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '16px',
    fontWeight: state.isSelected ? 'bold' : 'normal',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1000,
  }),
};

const propTypes = {};

const DashboardLogs = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const currentDate = new Date();
  const firstDayPreviousMonth = getFirstDayPreviousMonth(currentDate);
  const [timeRangeStart, setTimeRangeStart] = useState(firstDayPreviousMonth);
  const [timeRangeEnd, setTimeRangeEnd] = useState(currentDate);

  const [isRefreshing, setIsRefreshing] = useState(false);

  const [organizations, setOrganizations] = useState([]);
  const [users, setUsers] = useState([]);
  const [isGettingOrganizations, setIsGettingOrganizations] = useState(true);
  const [orgOptions, setOrgOptions] = useState([]);

  const initSelectedOrgs = () => {
    const savedSelectedOrgs = JSON.parse(
      localData.get('selected_organizations')
    );
    const res = savedSelectedOrgs ? savedSelectedOrgs : [];
    return res;
  };
  const [selectedOrgs, setSelectedOrgs] = useState(initSelectedOrgs());

  useEffect(() => {
    const getAllOrgs = async () => {
      let allOrgs = localData.get('all_organizations');
      if (!allOrgs) {
        const res = await api.get(`${SHARK_API.ORGANIZATIONS}`, {});
        localData.set('all_organizations', JSON.stringify(res.data));
        allOrgs = localData.get('all_organizations');
      }
      setOrganizations(JSON.parse(allOrgs));
    };

    const getAllUsers = async () => {
      let allUsers = localData.get('all_users');
      if (!allUsers) {
        const res = await api.get(`${SHARK_API.USERS}`, {});
        const tmp = res.data.map((item) => {
          return {
            id: item.id,
            username: item.username,
            firstName: item.firstName,
            lastName: item.lastName,
            userType: item.userType,
          };
        });
        localData.set('all_users', JSON.stringify(tmp));
        allUsers = localData.get('all_users');
      }
      setUsers(JSON.parse(allUsers));
    };

    setIsGettingOrganizations(true);
    getAllOrgs();
    setIsGettingOrganizations(false);
    getAllUsers();
  }, []);

  useEffect(() => {
    const options = organizations.map((org) => ({
      value: org.id,
      label: org.name,
    }));
    setOrgOptions(options);
  }, [organizations]);

  const [{ data, error }, fetchProject] = useApi.get(SHARK_API.LOGS, {
    params: {
      timeRangeStart: timeRangeStart,
      timeRangeEnd: timeRangeEnd,
      selectedOrgIds: JSON.stringify(selectedOrgs.map((item) => item.value)),
    },
    baseURL: process.env.REACT_APP_API_URL,
  });

  useEffect(() => {
    localData.set('selected_organizations', JSON.stringify(selectedOrgs));
    const fetchData = async () => {
      await fetchProject();
    };
    fetchData();
  }, [selectedOrgs, fetchProject]);

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { logs } = data;

  const handleRefreshClick = async () => {
    setIsRefreshing(true);
    await fetchProject();
    setIsRefreshing(false);
  };

  logs.sort((log1, log2) => log2.id - log1.id);

  return (
    <Fragment>
      <Grid container style={{ marginBottom: '10px', marginTop: '40px' }}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <MDBox ml={4}>
              <MDTypography
                variant="h6"
                gutterBottom
                style={{
                  fontWeight: 'bold',
                  marginTop: '16px',
                  marginRight: '18px',
                  textAlign: 'right',
                  alignSelf: 'stretch',
                }}>
                {`請求期間を選択: `}
              </MDTypography>
            </MDBox>
            <ThemeProvider theme={getTablesTheme(darkMode)}>
              <CustomDatePicker
                selectedDate={timeRangeStart}
                setSelectedDate={setTimeRangeStart}
                labelText="から"
              />
            </ThemeProvider>
            <ThemeProvider theme={getTablesTheme(darkMode)}>
              <CustomDatePicker
                selectedDate={timeRangeEnd}
                setSelectedDate={setTimeRangeEnd}
                labelText="まで"
                minDate={timeRangeStart}
              />
            </ThemeProvider>

            <RefreshButton
              tooltipTitle="データを更新する"
              handleRefreshClick={handleRefreshClick}
              isRefreshing={isRefreshing}
            />
          </Stack>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px', marginTop: '10px' }}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <MDBox ml={4}>
              <MDTypography
                variant="h6"
                gutterBottom
                style={{
                  fontWeight: 'bold',
                  marginTop: '16px',
                  marginRight: '18px',
                  textAlign: 'right',
                  alignSelf: 'stretch',
                }}>
                {`Organizationを選択: `}
              </MDTypography>
            </MDBox>

            <Select
              isClearable
              isSearchable
              closeMenuOnSelect={false}
              defaultValue={selectedOrgs}
              isMulti
              options={orgOptions}
              styles={customSelectStyles}
              onChange={setSelectedOrgs}
            />
          </Stack>
        </Grid>
      </Grid>

      {organizations.length > 0 && users.length > 0 && (
        <Card sx={{ marginTop: 3, overflow: 'visible' }}>
          <MDBox>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Table logs={logs} organizations={organizations} users={users} />
            </LocalizationProvider>
          </MDBox>
        </Card>
      )}
    </Fragment>
  );
};

DashboardLogs.propTypes = propTypes;

export default withRouter(DashboardLogs);
