import axios from 'axios';
import { getStoredAuthToken } from './authToken';
import { objectToQueryString } from './url';
// import localData from 'shared/utils/localData';
// import { USER_COMMENT } from 'shared/constants/comment';

const REQUEST_TYPES = {
  GET: 'get',
  // POST: 'post',
  // PATCH: 'patch',
  // DELETE: 'delete',
  // PUT: 'put',
};

const defaults = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: (variables) => ({
    'Content-Type': 'application/json',
    authorization: getStoredAuthToken(),
    ...variables.headers,
  }),
  error: {
    code: 'INTERNAL_ERROR',
    message:
      'Something went wrong. Please check your internet connection or contact our support.',
    status: 503,
    data: {},
  },
};

const api = (method, url, variables) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${defaults.baseURL}${url}`,
      method,
      headers: defaults.headers(variables),
      params: variables.params,
      data: variables.data,
      paramsSerializer: objectToQueryString,
      onDownloadProgress: variables.onDownloadProgress,
    }).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

const Api = {
  get: (...args) => api(REQUEST_TYPES.GET, ...args),
  // post: (...args) => api(REQUEST_TYPES.POST, ...args),
  // put: (...args) => api(REQUEST_TYPES.PUT, ...args),
  // patch: (...args) => api(REQUEST_TYPES.PATCH, ...args),
  // delete: (...args) => api(REQUEST_TYPES.DELETE, ...args),
};

export default Api;
