// import React, { Fragment, useState } from 'react';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';

const propTypes = {
  user: PropTypes.any,
};

const DetailPanelContent = ({ user }) => {
  return (
    <Box
      sx={{
        margin: 2,
        padding: 2,
        // border: '1px solid #ccc',
        // borderRadius: '4px',
        // display: 'grid',
      }}>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <ReactJson src={user} />
        </Grid>
        {/* // end of grid items */}
      </Grid>
    </Box>
  );
};

DetailPanelContent.propTypes = propTypes;

export default DetailPanelContent;
