import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import Select from 'react-select';
// import { Route } from 'react-router-dom';

//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import intl from 'react-intl-universal';
import { ThemeProvider } from '@mui/material/styles';
import { Grid } from '@material-ui/core';
import Card from '@mui/material/Card';
// import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
// functions
import api from 'shared/utils/api';
import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import { getFirstDayPreviousMonth } from 'shared/utils/dateTime';

// constants
import { SHARK_API } from 'shared/constants/apis';
// import { USER_DATA } from 'shared/constants/users';
// import { MatchPropTypes } from 'shared/propTypes/matchType';
// import { HistoryProptypes } from 'shared/propTypes/historyType';

// components and parts
import { PageLoader, PageError, Modal, RefreshButton } from 'components';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';
import { CustomDatePicker } from 'pages/parts/DatePicker';
import Table from './table';
// import { USER_COMMENT } from 'shared/constants/comment';

const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '40px', // Custom height
    minWidth: '400px',
    maxWidth: '800px',
  }),

  multiValue: (provided, state) => ({
    ...provided,
    height: '30px', // Set the height of the chip
    display: 'flex',
    alignItems: 'center', // This ensures vertical centering of the content
    justifyContent: 'center', // This centers the content horizontally (if needed)
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '16px',
    fontWeight: state.isSelected ? 'bold' : 'normal',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1000,
  }),
};

const propTypes = {};

const DashboardUsers = () => {
  // const [users, setUsers] = useState([]);

  // useEffect(() => {
  //   const getAllUsers = async () => {
  //     let allUsers = localData.get('all_users');
  //     if (!allUsers) {
  //       const res = await api.get(`${SHARK_API.USERS}`, {});
  //       const tmp = res.data.map((item) => {
  //         return {
  //           id: item.id,
  //           username: item.username,
  //           firstName: item.firstName,
  //           lastName: item.lastName,
  //           userType: item.userType,
  //         };
  //       });
  //       localData.set('all_users', JSON.stringify(tmp));
  //       allUsers = localData.get('all_users');
  //     }
  //     setUsers(JSON.parse(allUsers));
  //   };
  //   getAllUsers();
  // }, []);

  const [{ data, error }, fetchProject] = useApi.get(SHARK_API.USERS, {
    params: {},
    baseURL: process.env.REACT_APP_API_URL,
  });

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const users = data.data;

  const tmp = users.map((item) => {
    return {
      id: item.id,
      username: item.username,
      firstName: item.firstName,
      lastName: item.lastName,
      userType: item.userType,
    };
  });
  localData.set('all_users', JSON.stringify(tmp));

  return (
    <Fragment>
      <Grid container style={{ marginBottom: '10px', marginTop: '10px' }}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <MDBox ml={4}>
              <MDTypography
                variant="h3"
                gutterBottom
                style={{
                  fontWeight: 'bold',
                  marginTop: '16px',
                  marginRight: '18px',
                  textAlign: 'right',
                  alignSelf: 'stretch',
                }}>
                {`All Users: `}
              </MDTypography>
            </MDBox>
          </Stack>
        </Grid>
      </Grid>

      <Card sx={{ marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Table users={users} />
          </LocalizationProvider>
        </MDBox>
      </Card>
    </Fragment>
  );
};

DashboardUsers.propTypes = propTypes;

export default withRouter(DashboardUsers);
