import React, { Fragment } from 'react';
import { withRouter } from 'react-router';

// import { Route } from 'react-router-dom';

//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import intl from 'react-intl-universal';
// import { ThemeProvider } from '@mui/material/styles';
// import { Grid } from '@material-ui/core';
import Card from '@mui/material/Card';
// import PropTypes from 'prop-types';
// import Stack from '@mui/material/Stack';
// functions
// import api from 'shared/utils/api';
import useApi from 'shared/hooks/api';
// import localData from 'shared/utils/localData';

// constants
import { SHARK_API } from 'shared/constants/apis';

// components and parts
import { PageLoader, PageError } from 'components';
import MDBox from 'components/MDBox';
import Table from './table';

const propTypes = {
  // match: PropTypes.shape(MatchPropTypes),
  // history: PropTypes.shape(HistoryProptypes),
  // type: PropTypes.string,
};

const DashboardOrganizations = () => {
  // const [controller] = useMaterialUIController();
  // const [organizations, setOrganizations] = useState([]);

  const [{ data, error }, fetchProject] = useApi.get(SHARK_API.ORGANIZATIONS, {
    params: {},
    baseURL: process.env.REACT_APP_API_URL,
  });

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const organizations = data.data;

  return (
    <Fragment>
      <Card sx={{ marginTop: 10, overflow: 'visible' }}>
        <MDBox>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Table organizations={organizations} />
          </LocalizationProvider>
        </MDBox>
      </Card>
    </Fragment>
  );
};

DashboardOrganizations.propTypes = propTypes;

export default withRouter(DashboardOrganizations);
