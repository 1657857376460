/* eslint react/prop-types: 0 */
import React, { useState, useMemo, useEffect } from 'react';
// import { withRouter } from 'react-router';
// import { Route } from 'react-router-dom';
//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_JA } from 'material-react-table/locales/ja';
// import { MRT_Localization_EN } from 'material-react-table/locales/en';
//Material UI Imports
// import {
//   Box,
//   // Button,
//   // ListItemIcon,
//   // MenuItem,
//   // Typography,
//   lighten,
//   // darken,
//   // useTheme,
// } from '@mui/material';

//Icons Imports
// import { AccountCircle, Send } from '@mui/icons-material';
// import EditIcon from '@mui/icons-material/Edit';
//Date Picker Imports - these should just be in your Context Provider
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import { ThemeProvider } from '@mui/material/styles';
// import { Grid } from '@material-ui/core';
// import Card from '@mui/material/Card';
import PropTypes from 'prop-types';
import { readableJapaneseDateFormat } from 'shared/utils/dateTime';
// import {
//   getIssueStatus,
//   IssueStatusKenshin,
// } from 'shared/constants/kenshin/logs';
// import { Status } from './styles';
// import { filterData, generateRowSelectionObject } from './utils';
import DetailPanelContent from './detailPanelContent';
// import { valueToString } from 'shared/utils/misc';
// import localData from 'shared/utils/localData';
import { generateLogContents } from './util';

const propTypes = {
  logs: PropTypes.any,
  organizations: PropTypes.any,
  users: PropTypes.any,
};

const Table = ({ logs, organizations, users }) => {
  const organizationsDict = organizations.reduce((acc, current) => {
    acc[current.id] = current.name;
    return acc;
  }, {});

  const usersDict = users.reduce((acc, current) => {
    acc[current.id] = current;
    return acc;
  }, {});

  // const theme = useTheme();
  const columns = useMemo(
    () => [
      {
        header: 'id', // display name
        accessorKey: 'id', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        size: 10,
      },
      {
        header: 'type',
        accessorKey: 'type',
        size: 10,
      },
      {
        header: 'ip',
        accessorKey: 'ip',
        size: 30,
        filterFn: 'fuzzy',
      },
      {
        header: 'contents',
        id: 'contents',
        size: 30,
        enableClickToCopy: true,
        filterFn: 'fuzzy',
        accessorFn: (row) => {
          return generateLogContents(row.type, row.contents);
        },
      },
      {
        header: 'organization',
        accessorKey: 'organization',
        size: 30,
        filterFn: 'fuzzy',
      },
      {
        header: 'user',
        accessorKey: 'userFullName',
        size: 30,
        filterFn: 'fuzzy',
      },
      {
        header: 'role',
        accessorKey: 'userType',
        size: 30,
        filterFn: 'fuzzy',
      },
      {
        header: 'createdAt',
        accessorKey: 'createdAt',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const createdAt = cell.getValue();
          return readableJapaneseDateFormat(createdAt);
        },
      },
    ],
    []
  );

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  useEffect(() => {
    const allData = logs.map((item, index) => {
      const uId = item.userId;
      return {
        index: index,
        organization: organizationsDict[item.organizationId],
        userFullName: `${usersDict[uId].lastName} ${usersDict[uId].firstName}`,
        userType: usersDict[uId].userType,
        ...item,
      };
    });
    setData(allData);
  }, [logs]);

  const [data, setData] = useState([]); // This is the data shown in the table; after going through filters;
  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    localization: {
      ...MRT_Localization_JA,
    },
    renderDetailPanel: ({ row }) =>
      data[row.id] ? <DetailPanelContent log={data[row.id]} /> : null,
    // enableRowPinning: true,
    enableStickyHeader: true,
    enableRowActions: false,
    enableExpandAll: false,
    getRowId: (row) => row.index,
    onPaginationChange: setPagination,
    state: {
      density: 'compact',
      showColumnFilters: true,
      pagination,
    },
    autoResetPageIndex: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      shape: 'rounded',
      variant: 'outlined',
    },

    muiTableContainerProps: { sx: { maxHeight: '75vh' } }, // set the max height of the table to be 60% of the viewport height;
    muiTablePaperProps: { style: { zIndex: '1' } },
    muiTableHeadCellProps: {
      sx: {
        fontSize: '17px',
      },
    },
    // muiTableBodyRowProps: ({ row }) => ({
    //   onClick: (event) => {
    //     console.info(data[row.id]);
    //   },
    //   sx: {
    //     cursor: 'pointer',
    //   },
    // }),
    muiTableBodyCellProps: {
      sx: {
        fontSize: '14px',
        '&:hover': {
          fontWeight: 'bold',
        },
      },
    },
  });

  return <MaterialReactTable table={table} />;
};

Table.propTypes = propTypes;

export default Table;
