import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';

// react-router components
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Self designed sub pages, compoenents and others
import Sidenav from './Sidenav';
import Home from './Home';
import Users from './Users';
import Organizations from './Organizations';
import Issues from './Issues';
import IssuesKenshin from './IssuesKenshin';
import Logs from './Logs';
import DashboardLayout from 'pages/parts/LayoutContainers/DashboardLayout';

// context
import { useMaterialUIController } from 'context';

// themes
import theme from 'assets/theme';
import themeDark from 'assets/theme-dark';

// Images
import brandWhite from 'assets/images/logos/iwg/iwg-white-logo-cropped.png';
import brandDark from 'assets/images/logos/iwg/iwg-dark-logo-cropped.png';

const Dashboard = () => {
  const [controller] = useMaterialUIController();
  const { sidenavColor, transparentSidenav, whiteSidenav, darkMode } =
    controller;

  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <div style={{ overFlowY: 'scroll', minWidth: '1500px' }}>
        <Sidenav
          color={sidenavColor}
          brand={
            (transparentSidenav && !darkMode) || whiteSidenav
              ? brandDark
              : brandWhite
          }
          brandName="Internal Monitor"
        />
        <DashboardLayout>
          <Switch>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/organizations">
              <Organizations />
            </Route>
            <Route path="/issues">
              <Issues />
            </Route>
            <Route path="/issues-kenshin">
              <IssuesKenshin />
            </Route>
            <Route path="/logs">
              <Logs />
            </Route>
            <Redirect from="*" to={'/home'} />
          </Switch>
        </DashboardLayout>
      </div>
    </ThemeProvider>
  );
};

export default Dashboard;
