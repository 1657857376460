import React from 'react';
import { withRouter } from 'react-router';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

const DashboardHome = () => {
  return (
    <Card sx={{ margin: 3, marginTop: 3, overflow: 'visible' }}>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <MDBox pl={1} mb={5} display="flex">
          <MDTypography variant="h3">
            {'Welcome to IWG Internal Monitor'}
          </MDTypography>
        </MDBox>
        <MDBox pl={1} mb={5} display="flex">
          <MDTypography variant="h3">
            {'Click page names on the right side bar to view pages'}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default withRouter(DashboardHome);
