import React, { Fragment } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';
// import ReactJson from 'react-json-view';
import { readableJapaneseDateFormat } from 'shared/utils/dateTime';
import { valueToString } from 'shared/utils/misc';

const propTypes = {
  comments: PropTypes.any,
};

const DetailPanelContentComments = ({ comments }) => {
  return (
    <Fragment>
      {comments.map((comment, index) => (
        <Box
          key={`comment index ${index}`}
          sx={{
            margin: 1,
            padding: 2,
            border: '1px solid #ccc',
          }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body3" gutterBottom>
                {`username: ${comment.username}`}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body3" gutterBottom>
                {`user id: ${comment.userId}`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body3" gutterBottom>
                {`Name: ${comment.lastName} ${comment.firstName}`}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant="body3" gutterBottom>
                {`domainType: ${comment.domainType}`}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant="body3" gutterBottom>
                {`isReport: ${valueToString(comment.isReport)}`}
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="body3" gutterBottom>
                {`body: ${valueToString(comment.body)}`}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body3" gutterBottom>
                {`留言时间: ${readableJapaneseDateFormat(comment.createdAt)}`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Fragment>
  );
};

DetailPanelContentComments.propTypes = propTypes;

export default DetailPanelContentComments;
