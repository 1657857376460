import { Box, Typography, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';

const propTypes = {
  organization: PropTypes.any,
};

const DetailPanelContent = ({ organization }) => {
  return (
    <Box
      sx={{
        margin: 2,
        padding: 2,
      }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Stack direction="row" spacing={2}>
            <Typography variant="body3" gutterBottom>
              {'contact:'}
            </Typography>
            <ReactJson src={organization.contact} />
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Stack direction="row" spacing={2}>
            <Typography variant="body3" gutterBottom>
              {'paymentMethod:'}
            </Typography>
            <ReactJson src={organization.paymentMethod} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

DetailPanelContent.propTypes = propTypes;

export default DetailPanelContent;
