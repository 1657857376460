// import React, { Fragment, useState } from 'react';
import { Box, Typography, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { PageLoader, PageError } from 'components';
import { readableJapaneseDateFormat } from 'shared/utils/dateTime';
import { valueToString } from 'shared/utils/misc';
import api from 'shared/utils/api';
import localData from 'shared/utils/localData';
import useApi from 'shared/hooks/api';
import { SHARK_API } from 'shared/constants/apis';

import DetailPanelContentAttachments from './components/attachments';
import DetailPanelContentComments from './components/comments';

const propTypes = {
  issue: PropTypes.any,
};

const DetailPanelContent = ({ issue }) => {
  const [{ data, error }, fetchIssue] = useApi.get(
    `${SHARK_API.ISSUES}/${issue.id}`,
    {}
  );

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { comments, attachments_src_org, attachments_dst_org } = data;

  return (
    <Box
      sx={{
        margin: 2,
        padding: 2,
        // border: '1px solid #ccc',
        // borderRadius: '4px',
        // display: 'grid',
      }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'id'}: {valueToString(issue.id)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'srcOrgId'}: {valueToString(issue.srcOrgId)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'dstOrgId'}: {valueToString(issue.dstOrgId)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'doctorInCharge'}: {valueToString(issue.doctorInCharge)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'doctorsInCharge'}: {valueToString(issue.doctorsInCharge)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'patientID'}: {valueToString(issue.patientID)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'patientName'}: {valueToString(issue.patientName)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'patientGender'}: {valueToString(issue.patientGender)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'updatedAt'}: {readableJapaneseDateFormat(issue.updatedAt)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'deadline'}: {readableJapaneseDateFormat(issue.deadline)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'description'}: {valueToString(issue.description)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'descriptionText'}: {valueToString(issue.descriptionText)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'emergency'}: {valueToString(issue.emergency)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'hasCreatedBill'}: {valueToString(issue.hasCreatedBill)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'hasCreatedWages'}: {valueToString(issue.hasCreatedWages)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'tags'}: {valueToString(issue.tags)}
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Stack direction="row" spacing={2}>
            <Typography variant="body3" gutterBottom>
              {'tagsForBillingAndWage:'}
            </Typography>
            <ReactJson src={issue.tagsForBillingAndWage} collapsed={true} />
          </Stack>
        </Grid>

        {/* // end of grid items */}
      </Grid>

      <Typography variant="body1" gutterBottom>
        {'Attachments By Source Organization: '}
      </Typography>

      <DetailPanelContentAttachments
        attachments={attachments_src_org}
        keyNameHeader="attachments_src_org"
      />

      <Typography variant="body1" gutterBottom sx={{ marginTop: 6 }}>
        {'Attachments By Destination Organization: '}
      </Typography>

      {attachments_dst_org && attachments_dst_org.length > 0 && (
        <DetailPanelContentAttachments
          attachments={attachments_dst_org}
          keyNameHeader="attachments_dst_org"
        />
      )}

      <Typography variant="body1" gutterBottom sx={{ marginTop: 6 }}>
        {'Comments: '}
      </Typography>

      <DetailPanelContentComments comments={comments} />
    </Box>
  );
};

DetailPanelContent.propTypes = propTypes;

export default DetailPanelContent;
