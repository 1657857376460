// import React, { Fragment, useState } from 'react';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
// import { PageLoader, PageError } from 'components';
// import { readableJapaneseDateFormat } from 'shared/utils/dateTime';
// import { valueToString } from 'shared/utils/misc';

const propTypes = {
  log: PropTypes.any,
};

const DetailPanelContent = ({ log }) => {
  return (
    <Box
      sx={{
        margin: 2,
        padding: 2,
        // border: '1px solid #ccc',
        // borderRadius: '4px',
        // display: 'grid',
      }}>
      <Grid container spacing={2}>
        {/* <Grid item xs={4}>
          <Typography variant="body3" gutterBottom>
            {'link'}: {valueToString(log.link)}
          </Typography>
        </Grid> */}

        <Grid item xs={10}>
          <ReactJson src={log} />
        </Grid>

        {/* // end of grid items */}
      </Grid>
    </Box>
  );
};

DetailPanelContent.propTypes = propTypes;

export default DetailPanelContent;
