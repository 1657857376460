/* eslint react/prop-types: 0 */
import React, { useState, useMemo, useEffect } from 'react';
// import { withRouter } from 'react-router';
// import { Route } from 'react-router-dom';
//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  // MRT_GlobalFilterTextField,
  // MRT_ToggleFiltersButton,
} from 'material-react-table';
import { MRT_Localization_JA } from 'material-react-table/locales/ja';
// import { MRT_Localization_EN } from 'material-react-table/locales/en';
//Material UI Imports
// import {
//   Box,
//   // Button,
//   // ListItemIcon,
//   // MenuItem,
//   // Typography,
//   lighten,
// } from '@mui/material';

//Icons Imports
// import { AccountCircle, Send } from '@mui/icons-material';
// import EditIcon from '@mui/icons-material/Edit';
//Date Picker Imports - these should just be in your Context Provider
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import { ThemeProvider } from '@mui/material/styles';
// import { Grid } from '@material-ui/core';
// import Card from '@mui/material/Card';
import PropTypes from 'prop-types';
import { readableJapaneseDateFormat } from 'shared/utils/dateTime';
// import {
//   getIssueStatus,
//   IssueStatusKenshin,
// } from 'shared/constants/kenshin/issues';
// import { Status } from './styles';
import DetailPanelContent from './detailPanelContent';
import { valueToString } from 'shared/utils/misc';

const propTypes = {
  issues: PropTypes.any,
};

const Table = ({ issues }) => {
  // const theme = useTheme();
  const columns = useMemo(
    () => [
      {
        header: '依頼ID', // display name
        accessorKey: 'title', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        size: 50,
        enableClickToCopy: true,
        filterFn: 'fuzzy',
        enableColumnActions: false,
      },
      {
        header: '完成创建',
        accessorKey: 'creationCompleted',
        size: 10,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return valueToString(value);
        },
      },

      {
        header: 'progress',
        accessorKey: 'progress',
        size: 30,
        filterFn: 'fuzzy',
      },
      {
        header: '受信時間(createdAt)',
        accessorKey: 'createdAt',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const createdAt = cell.getValue();
          return readableJapaneseDateFormat(createdAt);
        },
      },
      {
        header: 'From',
        accessorKey: 'srcOrgName',
        size: 100,
        enableClickToCopy: true,
        filterFn: 'fuzzy',
      },
      {
        header: 'To',
        accessorKey: 'dstOrgName',
        size: 100,
        enableClickToCopy: true,
        filterFn: 'fuzzy',
      },
    ],
    []
  );

  useEffect(() => {
    const allData = issues.map((issue, index) => {
      return {
        index: index,
        ...issue,
      };
    });
    setData(allData);
  }, [issues]);

  const [data, setData] = useState([]); // This is the data shown in the table; after going through filters;

  const [pinnedRowIndexes, setPinnedRowIndexes] = useState([]);

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    localization: {
      ...MRT_Localization_JA,
    },
    renderDetailPanel: ({ row }) =>
      issues[row.id] ? <DetailPanelContent issue={issues[row.id]} /> : null,
    enableRowPinning: true,
    // positionExpandColumn: 'last',
    rowPinningDisplayMode: 'sticky', // default
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => {
        const rowIsPinned = row.getIsPinned();
        if (rowIsPinned) {
          setPinnedRowIndexes([]);
        } else {
          setPinnedRowIndexes([row.index]);
        }
      },
    }),

    enableStickyHeader: true,
    // enableRowActions: false,
    enableExpandAll: false,
    getRowId: (row) => row.index,
    state: {
      density: 'compact',
      showColumnFilters: true,
      columnVisibility: { pin: false },
      rowPinning: {
        top: pinnedRowIndexes,
      },
    },
    autoResetPageIndex: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      shape: 'rounded',
      variant: 'outlined',
    },

    muiTableContainerProps: { sx: { maxHeight: '75vh' } }, // set the max height of the table to be 60% of the viewport height;
    muiTablePaperProps: { style: { zIndex: '1' } },
    muiTableHeadCellProps: {
      sx: {
        fontSize: '17px',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.info(data[row.id]); // more work later
      },
      sx: {
        cursor: 'pointer',
      },
    }),
    muiTableBodyCellProps: {
      sx: {
        fontSize: '14px',
        '&:hover': {
          fontWeight: 'bold',
        },
      },
    },
    muiDetailPanelProps: () => ({
      sx: {
        backgroundColor: 'rgba(246,255,62,0.1)',
        // backgroundColor: 'rgba(249,130,130,0.1)',
      },
    }),
  });

  return <MaterialReactTable table={table} />;
};

Table.propTypes = propTypes;

export default Table;
