import React, { Fragment, useMemo, useEffect, useState } from 'react';
import { Box, Typography, Grid, lighten } from '@mui/material';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { readableJapaneseDateFormat } from 'shared/utils/dateTime';
import { valueToString } from 'shared/utils/misc';

import {
  MaterialReactTable,
  useMaterialReactTable,
  // MRT_GlobalFilterTextField,
  // MRT_ToggleFiltersButton,
} from 'material-react-table';
import { MRT_Localization_JA } from 'material-react-table/locales/ja';
import DetailPanelContent from './caseTableDetailPanelContent';

const propTypes = {
  cases: PropTypes.any,
};

const DetailPanelContentCaseTable = ({ cases }) => {
  // const theme = useTheme();
  const columns = useMemo(
    () => [
      {
        header: 'idx', // display name
        accessorKey: 'index',
        size: 10,
        // enableColumnFilter: false,
      },
      {
        header: 'filename',
        accessorKey: 'filename',
        size: 10,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return valueToString(value);
        },
      },
      {
        header: 'patientId',
        accessorKey: 'patientId',
        size: 10,
        filterFn: 'fuzzy',
      },
      {
        header: 'studyUID',
        accessorKey: 'studyInstanceUID',
        size: 10,
        filterFn: 'fuzzy',
      },
      {
        header: 'modality',
        accessorKey: 'modality',
        size: 10,
        filterFn: 'fuzzy',
      },
      {
        header: 'bodypart',
        accessorKey: 'bodypart',
        size: 10,
        filterFn: 'fuzzy',
      },
      {
        header: 'doctor',
        accessorKey: 'doctorFullName',
        size: 10,
      },
      {
        header: 'diagnosis',
        accessorKey: 'diagnosis',
        size: 10,
        enableClickToCopy: true,
        filterFn: 'fuzzy',
      },
    ],
    []
  );

  useEffect(() => {
    const allData = cases.map((item, index) => {
      return {
        index: index,
        doctorFullName: `${item.doctorLastName} ${item.doctorFirstName}`,
        ...item,
      };
    });
    setData(allData);
  }, [cases]);

  const [data, setData] = useState([]); // This is the data shown in the table; after going through filters;
  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    localization: {
      ...MRT_Localization_JA,
    },

    renderDetailPanel: ({ row }) =>
      cases[row.id] ? <DetailPanelContent thisCase={cases[row.id]} /> : null,

    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          // theme.palette.mode === 'dark'
          //   ? 'rgba(255,210,244,0.1)'
          //   : 'rgba(0,0,0,0.1)',
          theme.palette.mode === 'dark'
            ? 'rgba(246,255,62,0.1)'
            : 'rgba(0,0,0,0.1)',
      }),
    }),
    // enableRowPinning: true,
    // positionExpandColumn: 'last',
    enableStickyHeader: true,
    enableRowActions: false,
    enableExpandAll: false,
    getRowId: (row) => row.index,
    state: {
      density: 'compact',
      showColumnFilters: true,
    },
    autoResetPageIndex: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      shape: 'rounded',
      variant: 'outlined',
    },

    muiTableContainerProps: { sx: { maxHeight: '50vh' } }, // set the max height of the table to be 60% of the viewport height;
    muiTablePaperProps: { style: { zIndex: '1' } },
    muiTableHeadCellProps: {
      sx: {
        fontSize: '14px',
      },
    },
    // muiTableBodyRowProps: ({ row }) => ({
    //   onClick: (event) => {
    //     console.info(data[row.id]);
    //   },
    //   sx: {
    //     cursor: 'pointer',
    //   },
    // }),
    muiTableBodyCellProps: {
      sx: {
        fontSize: '14px',
        '&:hover': {
          fontWeight: 'bold',
        },
      },
    },
    // renderTopToolbar: ({ table }) => {
    //   return (
    //     <Box
    //       sx={(theme) => ({
    //         backgroundColor: lighten(theme.palette.background.default, 0.05),
    //         display: 'flex',
    //         gap: '0.5rem',
    //         p: '8px',
    //         justifyContent: 'space-between',
    //       })}>
    //       <Box
    //         sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}></Box>
    //       <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
    //         <Typography>{`表示されている症例数: ${data.length} 件`}</Typography>
    //       </Box>
    //     </Box>
    //   );
    // },
  });

  return <MaterialReactTable table={table} />;
};

DetailPanelContentCaseTable.propTypes = propTypes;

export default DetailPanelContentCaseTable;
