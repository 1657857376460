/* eslint react/prop-types: 0 */
import React, { useMemo, useState } from 'react';
// import ReactJson from 'react-json-view';
//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_JA } from 'material-react-table/locales/ja';

import PropTypes from 'prop-types';
import { readableJapaneseDateFormat } from 'shared/utils/dateTime';
import { valueToString } from 'shared/utils/misc';
import DetailPanelContent from './detailPanelContent';

const propTypes = {
  organizations: PropTypes.any,
};

const Table = ({ organizations }) => {
  const columns = useMemo(
    () => [
      {
        header: 'id',
        accessorKey: 'id',
        size: 10,
        enableClickToCopy: true,
        filterFn: 'fuzzy',
        enableColumnActions: false,
      },
      {
        header: 'name',
        accessorKey: 'name',
        size: 10,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return valueToString(value);
        },
      },

      {
        header: 'organizationCode',
        accessorKey: 'organizationCode',
        size: 10,
        filterFn: 'fuzzy',
      },
      {
        header: 'ouid',
        accessorKey: 'ouid',
        size: 10,
        filterFn: 'fuzzy',
      },
      {
        header: 'settingsId',
        accessorKey: 'settingsId',
        size: 10,
        filterFn: 'fuzzy',
      },
      {
        header: 'ownerId',
        accessorKey: 'ownerId',
        size: 10,
        filterFn: 'fuzzy',
      },
      {
        header: 'createdAt',
        accessorKey: 'createdAt',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const createdAt = cell.getValue();
          return readableJapaneseDateFormat(createdAt);
        },
      },
    ],
    []
  );

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const data = organizations;

  const table = useMaterialReactTable({
    columns,
    data,
    localization: {
      ...MRT_Localization_JA,
    },
    renderDetailPanel: ({ row }) =>
      organizations[row.id] ? (
        <DetailPanelContent organization={organizations[row.id]} />
      ) : null,
    enableStickyHeader: true,
    enableRowActions: false,
    enableExpandAll: false,
    getRowId: (row) => row.index,
    onPaginationChange: setPagination,
    state: {
      density: 'compact',
      showColumnFilters: true,
      pagination,
    },
    autoResetPageIndex: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      shape: 'rounded',
      variant: 'outlined',
    },

    muiTableContainerProps: { sx: { maxHeight: '80vh' } }, // set the max height of the table to be 60% of the viewport height;
    muiTablePaperProps: { style: { zIndex: '1' } },
    muiTableHeadCellProps: {
      sx: {
        fontSize: '17px',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.info(data[row.id]);
      },
      sx: {
        cursor: 'pointer',
      },
    }),
    muiTableBodyCellProps: {
      sx: {
        fontSize: '14px',
        '&:hover': {
          fontWeight: 'bold',
        },
      },
    },
  });

  return <MaterialReactTable table={table} />;
};

Table.propTypes = propTypes;

export default Table;
