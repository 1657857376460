import React, { Fragment } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { readableJapaneseDateFormat } from 'shared/utils/dateTime';
// import { valueToString } from 'shared/utils/misc';

const propTypes = {
  attachments: PropTypes.any,
  keyNameHeader: PropTypes.string,
};

const DetailPanelContentAttachments = ({ attachments, keyNameHeader }) => {
  return (
    <Fragment>
      {attachments.map((attachment, index) => (
        <Box
          key={`${keyNameHeader} index ${index}`}
          sx={{
            margin: 1,
            padding: 2,
            border: '1px solid #ccc',
          }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body3" gutterBottom>
                {`title: ${attachment.title}`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body3" gutterBottom>
                {`ID: ${attachment.id} , ${attachment.attachmentIdentifierId}`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body3" gutterBottom>
                {`status: ${attachment.status}`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body3" gutterBottom>
                {`type: ${attachment.type}`}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body3" gutterBottom>
                {`createdAt: ${readableJapaneseDateFormat(
                  attachment.createdAt
                )}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body3" gutterBottom>
                {`updatedAt: ${readableJapaneseDateFormat(
                  attachment.updatedAt
                )}`}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body3" gutterBottom>
                {`webFilePath: ${attachment.webFilePath}`}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body3" gutterBottom>
                {`message: ${attachment.message}`}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant="body3" gutterBottom>
                {`structuredJson:`}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <ReactJson src={attachment.structuredJson} />
            </Grid>
          </Grid>
        </Box>
      ))}
    </Fragment>
  );
};

DetailPanelContentAttachments.propTypes = propTypes;

export default DetailPanelContentAttachments;
