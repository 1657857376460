import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Welcome from 'pages/Welcome';
import Dashboard from 'pages/Dashboard';
import BaseApp from 'root/base';
import localData from 'shared/utils/localData';
import { SnackbarProvider } from 'notistack';

const App = () => {
  const [currentUser, setCurrentUser] = useState('NotChecked');

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(function (result) {
        setCurrentUser(result);
      })
      .catch(function (error) {
        console.error(error);
        setCurrentUser('NotSignedIn');
      });
  }, []);

  if (currentUser === 'NotChecked') {
    return <div></div>; // temporary work-around;
  } else if (currentUser === 'NotSignedIn') {
    return (
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Switch>
          <Route path="/">
            <Welcome />
          </Route>
          <Redirect from="*" to="/" />
        </Switch>
      </SnackbarProvider>
    );
  } else {
    return (
      <BaseApp>
        <Dashboard currentUser={currentUser} />
      </BaseApp>
    );
  }
};

export default App;
